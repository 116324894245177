$(document).ready(function () {
	AOS.init({
		duration: 1000
	});

	// Create an instance of Notyf
	var notyf = new Notyf({
		duration: 3000,
	});

	/*
	notyf.error({
		message: 'Accept the terms before moving forward',
		duration: 0,
		dismissible: true
	});
	*/

	/*
	$(".lightgallery").lightGallery({
		share: false
	});
	*/

	$("#print").click(function () {
		print();
	});

	$('ul.main_menu li:first').addClass('first');
	$('ul.main_menu li:last').addClass('last');

	if ($('#btn_forgot').length) {
		var pristine = new Pristine(document.getElementById('pw_reset'), {
			classTo: null,
			errorTextParent: null,
		});

		$('#pw_msg').hide();
		$('#btn_forgot').click(function (e) {
			e.preventDefault();

			if (pristine.validate()) {
				$.ajax({
					method: 'post',
					url: '/ajax/request-password',
					success: requestPasswordCb,
					data: {'email': $('input[name=pw_reset_email]').val()}
				});
			}
		});
	}

	$('a.show-overlay').click(function (e) {
		e.preventDefault();
		$('.overlay-bg').addClass('hide');
		$('.overlay-bg.overlay-id-' + $(this).data('overlay-id')).removeClass('hide')
	});

	$('.overlay a:first-child').click(function (e) {
		e.preventDefault();
		$(this).parent().parent().addClass('hide');
	});

	initChangeLanguage();
	initProfileNav();
	initBurgerMenu();
	initWelcomeVideoButtonClick();
	initUploadImage();

	/*
	$('a.more').click(function() {
		var $this = $(this);
		var $divMore = $(this).prev();

		$divMore.slideToggle(400, function() {
			if ($divMore.is(':hidden')) {
				$this.text($this.data('trans-open'));
			} else {
				$this.text($this.data('trans-close'));
			}
		});
	});
	*/

	$('button.more').click(function () {
		var $this = $(this);
		var $divMore = $(this).prev();

		$divMore.slideToggle(400, function () {
			if ($divMore.is(':hidden')) {
				$this.find('span').text($this.data('trans-open'));
			} else {
				$this.find('span').text($this.data('trans-close'));
			}
		});
	});

	addEventlisteners();
});

function initUploadImage() {
	FilePond.registerPlugin(FilePondPluginFileValidateSize, FilePondPluginImagePreview);

	const pond = FilePond.create(
		document.querySelector('input[type="file"]'),
		{
			labelIdle: `Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`,
			credits: null,
			/*
			imagePreviewHeight: 170,
			imageCropAspectRatio: '1:1',
			imageResizeTargetWidth: 200,
			imageResizeTargetHeight: 200,
			stylePanelLayout: 'compact circle',
			styleLoadIndicatorPosition: 'center bottom',
			styleProgressIndicatorPosition: 'center bottom',
			styleButtonRemoveItemPosition: 'center bottom',
			styleButtonProcessItemPosition: 'center bottom',
			*/
			server: {
				process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

					// fieldName is the name of the input field
					// file is the actual file object to send
					const formData = new FormData();
					formData.append(fieldName, file, file.name);

					const request = new XMLHttpRequest();
					request.open('POST', '/ajax-member/profile-image-process');

					// Should call the progress method to update the progress to 100% before calling load
					// Setting computable to false switches the loading indicator to infinite mode
					request.upload.onprogress = (e) => {
						progress(e.lengthComputable, e.loaded, e.total);
					};

					// Should call the load method when done and pass the returned server file id
					// this server file id is then used later on when reverting or restoring a file
					// so your server knows which file to return without exposing that info to the client
					request.onload = function () {
						if (request.status >= 200 && request.status < 300) {
							// the load method accepts either a string (id) or an object
							load(request.responseText);
						} else {
							// Can call the error method if something is wrong, should exit after
							error();
						}
					};

					request.send(formData);

					// Should expose an abort method so the request can be cancelled
					return {
						abort: () => {
							// This function is entered if the user has tapped the cancel button
							request.abort();

							// Let FilePond know the request has been cancelled
							abort();
						}
					};
				},
				revert: '/ajax-member/profile-image-revert',
				//restore: 'filepond/restore?id=',
				//fetch: 'filepond/fetch?data=',
				load: '/ajax-member/profile-image-load?id=',
			},
			// server: '/ajax-member/profile-image'
			files: [
				{
					// the server file reference
					source: '1',

					// set type to local to indicate an already uploaded file
					options: {
						type: 'local'
					}
				}
			]
		}
	);
}

function isMobile() {
	return window.matchMedia("only screen and (max-width: 760px)").matches;
}

function initWelcomeVideoButtonClick() {
	$('.welcome-message a.btn').on('click', function (event) {
		event.preventDefault();

		if (isMobile()) {
			window.open('/media/video/test.mp4', "_blank")
		} else {
			var modal = new tingle.modal({
				closeMethods: ['overlay', 'escape'],
			});

			modal.setContent('<video width="100%" poster="/media/video/event-trailer.jpg" autobuffer controls><source src=".ogv" type="video/ogg" /><source src=".mp4" type="video/mp4" /><source src=".webm" type="video/webm" />No Video-Support</video>');
			modal.open();
		}
	});
}

function initChangeLanguage() {
	var $langToggleNav = $('#lang-toogle-menu');
	var $langNav = $('#language-menu');

	$langToggleNav.on('click', function (event) {
		event.preventDefault();
		$(this).toggleClass('active');
		$langNav.toggleClass('d-none').find('a.active').focus();
	});

	$('html').click(function (event) {
		if (event.target.id !== $langToggleNav.attr('id') && event.target.id !== $langNav.attr('id')) {
			$langToggleNav.removeClass('active');
			$langNav.addClass('d-none');
		}
	});
}

function initProfileNav() {
	var $toggleNav = $('a#profile-toggle-nav');
	var $nav = $('ul#profile-nav');

	$toggleNav.on('click', function (event) {
		event.preventDefault();
		$nav.toggleClass('d-none').find('a.active').focus();
	});

	$('html').click(function (event) {
		if (event.target.parentElement.id !== $toggleNav.attr('id') && event.target.parentElement.id !== $nav.attr('id')) {
			$toggleNav.removeClass('active');
			$nav.addClass('d-none');
		}
	});
}

function initBurgerMenu() {
	$burger = $('a.burger');
	$mainMenu = $('ul.main_menu');

	$burger.on('click', function (event) {
		event.preventDefault();
		$('body').toggleClass('menu-open');
		$(this).toggleClass('active');
		$mainMenu.toggleClass('d-none');
	});
}

function requestPasswordCb(response) {
	$('#pw_msg').text(response.msg).show('fast', function () {
		$(this).delay(3000).hide('fast');
	});
}

var old_activity = null;

// Platziert ein Element in der Bildschirmmitte.
jQuery.fn.center = function (refElementIdWidth) {
	var top = (($(window).height() - this.outerHeight()) / 3) + $(window).scrollTop();
	var left = (($(window).width() - this.outerWidth()) / 2) + $(window).scrollLeft();

	this.css("position", "absolute");
	this.css("z-index", "1000000");

	if (refElementIdWidth != undefined) {
		//left = ($(refElementIdWidth).width() - this.width()) / 2;
		left = (960 - this.width()) / 2;
	}

	this.css("top", top + "px");
	this.css("left", left + "px");

	return this;
}

function addEventlisteners() {
	$(document).keyup(function (e) {
		if (e.keyCode == 27) {
			$('.overlay-bg').addClass('hide');
			/*
			$('.activity_detail').each(function (index, activityDetail) {
				if ($(activityDetail).is(':visible')) {
					hide($(activityDetail).attr('id').replace('_detail', ''));
				}
			})

			hideLayer();
			*/
		}
	});
}

function showPictureGalleryLayer(filename) {
	var bigImgLink = $("div.layer").children('a');

	// Evtl. altes, großes Bild des Layers löschen
	bigImgLink.children('img').remove();

	// URL des Links auf das große Bild setzen
	bigImgLink.attr('href', '/media/images/gallery/large/' + filename);

	// Bild in mittlerer Größe einfügen
	bigImgLink.prepend('<img src="/media/images/gallery/medium/' + filename + '" alt="" />');
	showLayer();
}

function showLayer() {
	$("div.layer").hide(0, function () {
		$("div.layer").css({opacity: 0.95});
		$("div.layer").center('#page');
		$("div.layer").show('fast');
	});
}

function hideLayer() {
	$("div.layer").hide("fast");
};